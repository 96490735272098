@charset "UTF-8";

main {
	max-width: 600px
}

#imageContainer {
    position: fixed;
    max-height: 40vh;
    top: 100px;
    left: 50vw;
}

#imageContainer img {
    /*height: inherit;*/
    width: 30vw;
}

video {
    margin-bottom: 20px;
}

#news p {
    display: block !important;
    margin: 0px !important;
    border: 0px !important;
    padding: 0px !important;
}

#news img {
    margin-top: 10px;
    width: 50%;
}

#tech-support-bubble {
    display: none;
    position: fixed;
    width: 180px;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
}

.center {
	margin-left: 580px;
}

footer {
    margin-top: 40px;
    margin-bottom: 20px;
}


@media screen and (max-width: 600px) {

    main {
        max-width: 90%;
        margin: 3%;
        margin-top: 10px;
    }

    #news img {
        width: 100%;
    }

    #imageContainer {
        position: fixed;
        width: 80vw;
        max-height: 80vh;
        top: 100px;
        left: 30px;
    }

    #imageContainer img {
        width: 100%;
    }

}